import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Image } from "antd";
import axios from "axios";

import "./ShowOrdersPageStyles.css";
import { NoutFound } from "../../components/NoutFound/NoutFound";
import { CONFIG } from "../../constants/config";

function ShowOrdersPage() {
  const [searchParams] = useSearchParams();
  const [details, setDetails] = useState([]);
  const [noutFound, setNoutFound] = useState(null);

  const { orderId } = useParams();

  const apiKey = searchParams.get("api_key");

  useEffect(() => {
    if (orderId) {
      axios
        .get(
          `${CONFIG.API_URL}/order/${orderId}?api_key=${apiKey}&is_show=show`
        )
        .then((res) => {
          if (res.data?.order && res.data?.order?.OrderDetail?.length) {
            setDetails(res.data?.order);
            setNoutFound(null);
          } else {
            setNoutFound(res.data?.message || "Ничего не найдено");
          }
        });
    }
  }, [orderId]);

  return (
    <>
      {noutFound ? (
        <NoutFound text={noutFound} />
      ) : (
        <div className="ShowOrdersPage">
          <div className="ShowOrdersPage-cards">
            <h4>
              Статус заказа:{" "}
              <span>{details?.OrderStatus?.additionalTitle}</span>
            </h4>
            {/* {details?.total && (
              <h4>
                Итого:{" "}
                <span>
                  {details.total} - {details.currency}
                </span>
              </h4>
            )} */}
            {details?.OrderDetail?.map((elm, inx) => {
              return (
                <div className="ShowOrdersPage-card" key={elm}>
                  <div className="ShowOrdersPage-card-border-left"></div>
                  <ul className="ShowOrdersPage-card-content">
                    <li>
                      <span>№:</span> <span>{inx + 1}</span>
                    </li>
                    <li>
                      <span>Ссылка:</span>
                      <span className="elipsis">
                        <a href={elm.link} target="_blank">
                          {elm.link}
                        </a>
                      </span>
                    </li>
                    <li>
                      <span>Цена:</span>
                      <span>{elm.price}</span>
                    </li>
                    <li>
                      <span>Кол-во:</span>
                      <span>{elm.quantity}</span>
                    </li>
                    <li>
                      <span>Статус:</span>
                      <span
                        className={`status-${
                          elm?.OrderDetailStatus?.name || "new"
                        } elipsis`}
                      >
                        {elm?.OrderDetailStatus?.title || "Новый"}
                      </span>
                    </li>
                    <li>
                      <span>Коментарии:</span>
                      <span className="elipsis">{elm.description}</span>
                    </li>
                    <li className="img-preview">
                      <span>Картинка:</span>
                      <span>
                        <Image
                          width={200}
                          src={
                            elm?.image?.length > 43
                              ? elm.image
                              : `${CONFIG.API_URL}/image/${elm?.image}`
                          }
                        />
                      </span>
                    </li>
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default ShowOrdersPage;
